exports.components = {
  "component---src-pages-aavolutionhouse-js": () => import("./../../../src/pages/aavolutionhouse.js" /* webpackChunkName: "component---src-pages-aavolutionhouse-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-js": () => import("./../../../src/pages/mobile.js" /* webpackChunkName: "component---src-pages-mobile-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sdk-js": () => import("./../../../src/pages/sdk.js" /* webpackChunkName: "component---src-pages-sdk-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-wallet-js": () => import("./../../../src/pages/wallet.js" /* webpackChunkName: "component---src-pages-wallet-js" */),
  "component---src-pages-web-3-ecosystem-js": () => import("./../../../src/pages/web3-ecosystem.js" /* webpackChunkName: "component---src-pages-web-3-ecosystem-js" */),
  "component---src-templates-batch-tx-js": () => import("./../../../src/templates/batch-tx.js" /* webpackChunkName: "component---src-templates-batch-tx-js" */)
}

