import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [isSupportWebp, setIsSupportWebp] = useState(true)

    useEffect(() => {
        setIsSupportWebp(document.body.classList.contains('webp'))
    }, [])


    return (
        <AppContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                isSupportWebp,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

AppProvider.propTypes = {
    children: PropTypes.node.isRequired,
}