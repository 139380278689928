/**
 * Shadow the chakra theme
 */
import { extendTheme } from '@chakra-ui/react';
import merge from 'lodash.merge';
import boTheme from '@blocto/web-chakra-theme';

const breakpoints = {
  sm: '512px',
  md: '768px',
  lg: '1200px',
  xl: '1440px',
  '2xl': '1920px',
};

const LayoutContainer = {
  baseStyle: {
    width: '100%',
    maxWidth: '1440px',
    minHeight: 'calc(100% - 500px)',
    px: { base: '20px', md: 'min(120px, 8.3%)' },
    margin: '0 auto',
    marginTop: '78px',
  },
};

const Button = {
  baseStyle: {
    h: { base: '36px', md: '44px' },
    p: 'space.2xs',
    fontSize: { base: 'size.body.3', md: 'size.body.2' },
    fontWeight: 'weight.s',
    borderRadius: '30px',
    _focus: {
      boxShadow: 'none',
    },
  },
  sizes: {
    lg: {
      height: '44px',
      px: 'space.2xs',
      p: 'space.2xs',
      fontSize: 'size.body.2',
      fontWeight: 'weight.s',
    },
    sm: {
      h: '36px',
      p: 'space.2xs',
      fontSize: 'size.body.3',
      fontWeight: 'weight.s',
    },
  },
  variants: {
    primary: {
      bg: 'interaction.primary',
      color: 'font.inverse',
      _hover: {
        bg: 'font.highlight.pressed',
        _disabled: {
          bg: 'interaction.primary',
        },
      },
    },
    white: {
      bg: 'background.primary',
      color: 'icon.text',
      _hover: {
        bg: 'rgba(255, 255, 255, 0.6)',
        borderColor: 'rgba(0, 50, 90, 0.70)',
        color: 'rgba(0, 50, 90, 0.70)',
        _disabled: {
          bg: 'background.primary',
          color: 'icon.text',
          borderColor: 'icon.text',
        },
      },
    },
    outline: {
      bg: 'background.primary',
      color: 'icon.text',
      border: '0.5px solid',
      borderColor: 'icon.text',
      _hover: {
        bg: 'rgba(255, 255, 255, 0.6)',
        borderColor: 'rgba(0, 50, 90, 0.70)',
        color: 'rgba(0, 50, 90, 0.70)',
        _disabled: {
          bg: 'background.primary',
          color: 'icon.text',
          borderColor: 'icon.text',
        },
      },
    },
    icon: {
      bg: 'rgba(249, 249, 249, 0.60)',
    },
    plain: {},
  },
  defaultProps: {
    size: '',
    variant: 'primary',
  },
};

const Tag = {
  baseStyle: {
    container: {
      border: '1px solid',
      fontWeight: 'weight.s',
      px: { base: 'space.s', md: 'space.m' },
      py: { base: 'space.2xs', md: 'space.2xs' },
      fontSize: { base: '10px', md: 'size.body.2' },
      lineHeight: { base: '15px', md: '130%' },
      borderRadius: '30px',
    },
  },
  variants: {
    white: {
      container: {
        color: 'font.inverse',
        borderColor: 'border.inverse',
      },
    },
    gray: {
      container: {
        color: 'boGlobalColor.bluegray.500',
        borderColor: 'boGlobalColor.bluegray.500',
      },
    },
    blue: {
      container: {
        color: 'icon.text',
        borderColor: 'icon.text',
      },
    },
  },
  sizes: {
    sm: {
      container: {
        px: 'space.s',
        py: 'space.2xs',
        fontSize: '10px',
        lineHeight: '15px',
        borderRadius: '30px',
      },
    },
    md: {
      container: {
        px: 'space.m',
        py: 'space.2xs',
        fontSize: 'size.body.2',
        lineHeight: '130%',
        borderRadius: '30px',
      },
    },
  },
  defaultProps: {
    size: '',
  },
};

const components = {
  LayoutContainer,
  Button,
  Tag,
};

export default extendTheme({
  ...merge(boTheme, {
    semanticTokens: {
      fonts: {
        body: "'FactorB', sans-serif",
        heading: "'FactorB', sans-serif",
      },
    },
  }),
  breakpoints,
  components,
  styles: {
    global: {
      html: {
        fontSize: '16px',
        color: 'font.primary',
      },
      button: {
        textRendering: 'geometricPrecision',
        WebkitTapHighlightColor: 'transparent',
      },
      '[role=button]': {
        WebkitTapHighlightColor: 'transparent',
      },
    },
  },
});
