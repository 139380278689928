import React from 'react';
import PropTypes from 'prop-types';
import { AppProvider } from '../context/index';

const RootElement = ({ children }) => <AppProvider>{children}</AppProvider>;


RootElement.propTypes = {
    children: PropTypes.node.isRequired,
}

export default RootElement;