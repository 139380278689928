module.exports = {
  siteMetadata: {
    author: '@BloctoApp',
    keywords:
      'account abstraction wallet, web3 dapps, smart contract wallet, crypto wallet integration, crypto learning',
    siteUrl: 'https://blocto.io/',
    image: 'https://blocto.io/images/blocto-social.jpg',
    square_image: 'https://blocto.io/images/logo-square.png',
    fb_app_id: '508291339938435',
  },
  trailingSlash: 'never',
  plugins: [
    'gatsby-plugin-react-helmet',
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'images',
        path: `${__dirname}/static/images`,
      },
    },
    'gatsby-transformer-sharp',
    'gatsby-plugin-sharp',
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'gatsby-starter-default',
        short_name: 'starter',
        start_url: '/',
        background_color: '#FFFFFF',
        display: 'minimal-ui',
        // icon: `${__dirname}/static/images/logo-square.png`, // This path is relative to the root of the site.
        icon: `${__dirname}/static/images/favicon/logo.png`, // This path is relative to the root of the site.
        icons: [
          {
            src: `/favicon/apple-touch-icon-16x16.png`,
            sizes: `16x16`,
            type: `image/png`,
          },
          {
            src: `/favicon/apple-touch-icon-32x32.png`,
            sizes: `32x32`,
            type: `image/png`,
          },
          {
            src: `/favicon/apple-touch-icon-128x128.png`,
            sizes: `128x128`,
            type: `image/png`,
          },
          {
            src: `/favicon/apple-touch-icon-192x192.png`,
            sizes: `192x192`,
            type: `image/png`,
          },
        ], // Add or remove icon sizes as desired
      },
    },
    'gatsby-plugin-sass',
    // {
    //   resolve: 'gatsby-plugin-web-font-loader',
    //   options: {
    //     google: {
    //       families: ['Noto+Sans:400,700', 'Work+Sans:400,600'],
    //     },
    //   },
    // },
    'gatsby-plugin-preload-fonts',
    {
      resolve: 'gatsby-plugin-sitemap',
      options: {
        output: '.',
        excludes: ['/web3-ecosystem/', '/download/', '/privacy/', '/terms/'],
      },
    },
    {
      resolve: 'gatsby-plugin-hubspot',
      options: {
        trackingCode: '20594756',
        respectDNT: false,
        productionOnly: true,
      },
    },
    {
      resolve: 'gatsby-plugin-intl',
      options: {
        // language JSON resource path
        path: `${__dirname}/src/intl`,
        // supported language
        languages: ['en', 'ja'],
        // language file path
        defaultLanguage: 'en',
        // option to redirect to `/en` when connecting `/`
        redirect: false,
      },
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        policy: [
          {
            userAgent: '*',
            disallow: ['/email-protection', '/serum', '/404', '/mobile'],
          },
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-google-gtag',
      options: {
        // You can add multiple tracking ids and a pageview event will be fired for all of them.
        trackingIds: [
          process.env.GATSBY_GA_ID, // Google Analytics / GA
        ],
        // This object is used for configuration specific to this plugin
        pluginConfig: {
          // Puts tracking script in the head instead of the body
          head: true,
          respectDNT: false,
        },
        gtagConfig: {
          anonymize_ip: true,
          cookie_expires: 0,
          debug_mode: true,
        },
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
    'gatsby-plugin-netlify',
    '@chakra-ui/gatsby-plugin',
    'gatsby-plugin-anchor-links',
  ],
};
